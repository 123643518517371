@tailwind base;
@tailwind components;
@tailwind utilities;



@layer base {
  body {
    font-weight: 400;
    @apply font-sans;
    @apply bg-white;
    @apply h-full;
    @apply w-full;
  }

  p {
    @apply text-gray-700;
    /* @apply md:text-base; */
    @apply text-body-sm;
    @apply md:text-body-md;
    @apply lg:text-body-lg;
    @apply leading-normal;
    @apply font-light;
    color: #252423;
  }

  h1 {
    @apply text-lg sm:text-xl md:text-3xl;
    @apply font-bold;
    @apply text-gray-900;
    color: #252423;
  }

  h2 {
    @apply text-xl md:text-3xl lg:text-4xl;
    color: #252423;
  }

  h3 {
    @apply text-lg md:text-2xl lg:text-3xl;
    color: #252423;
  }

  h5 {
    color: #252423;
  }

  /* Forms */
  label {
    display: block;
    margin: 5px 0;
    color: var(--custom-color-secondary);
  }

  input {
    width: 100%;
    border-radius: 5px;
    /* border: var(--custom-border); */
    padding: 8px;
    font-size: 0.9rem;
    background-color: var(--custom-bg-color);
    color: var(--custom-color);
  }

  input[disabled] {
    color: var(--custom-color-secondary);
  }
}

@layer components {
  .button {
    @apply bg-orange_cone;
    @apply hover:bg-opacity-70;
    @apply text-white;
    @apply text-center;
    @apply py-2;
    @apply px-4 sm:px-8 md:px-16;
    @apply rounded-lg;
    @apply whitespace-nowrap;
    @apply text-body-xs sm:text-body-sm;
    @apply disabled:bg-gray-200;
    @apply disabled:cursor-not-allowed;
    @apply aria-[disabled=true]:bg-gray-200;
    height: fit-content
  }

  .button-inline {
    @apply button;
    @apply px-2;
    @apply py-1;
  }

  .mi-card {
    @apply bg-gray_background;
    @apply py-9;
    @apply px-6;
    @apply rounded-lg;
  }

  .mi-row {
    @apply mb-6;
  }

  .mi-row:last-child {
    @apply mb-0
  }

  .mi-card-last {
    @apply mi-card;
    @apply mb-0;
  }

  /* Uploadly Component */
  .dropzone {
    @apply p-4;
    @apply sm:p-24;
    @apply w-full;
  }

  .dropzone button {
    @apply bg-orange_cone;
  }

  .dropzone.drag-over button {
    @apply hidden;
  }

  .dropzone.drag-over div {
    @apply button;
    @apply py-2;
    @apply max-w-sm;
  }
  .dropzone:not(.drag-over) div {
    @apply hidden;
  }

  #nprogress {
    pointer-events: none;
  }
  #nprogress .bar {
    background: #FF763B;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
  }
  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #FF763B, 0 0 5px #FF763B;
    opacity: 1.0;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
        -ms-transform: rotate(3deg) translate(0px, -4px);
            transform: rotate(3deg) translate(0px, -4px);
  }

  .arrow-style-tab {
    @apply relative;
    @apply text-sm;
    @apply ui-selected:font-semibold;
    @apply focus:outline-none;
    @apply border-0;
    padding-left: calc(var(--arrow-style-tab-h-rem) * 0.5rem);
    @apply bg-gray-200;
    height: calc(var(--arrow-style-tab-h-rem) * 1rem);
    @apply first:pl-0;
    @apply last:pr-4;
    @apply first:rounded-tl-md;
    @apply last:rounded-tr-md;
    padding-right: 0.5rem;
  }

  .arrow-style-tab > span {
    display: inline-block;
    height: 100%;
    @apply pl-4;
    line-height: calc(var(--arrow-style-tab-h-rem) * 1rem);
  }

  :root {
    --arrow-style-tab-h-rem: 2;
  }

  .arrow-style-tab > span::after {
    content: "";
    position: absolute;
    display: inline-block;
    width: calc(1rem * sqrt((var(--arrow-style-tab-h-rem) * var(--arrow-style-tab-h-rem)) / 2));
    height: calc(1rem * sqrt((var(--arrow-style-tab-h-rem) * var(--arrow-style-tab-h-rem)) / 2));
    top: 0;
    border-top-right-radius: 3px;
    transform-origin: top left;
    transform: translateX(0.5rem) rotate(45deg);
    @apply !border-gray-400;
    border-top: 2px solid;
    border-right: 2px solid;
    z-index: 1;
    @apply bg-gray-200;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 0%);
  }

  [data-headlessui-state="selected"].arrow-style-tab, [data-headlessui-state="selected"].arrow-style-tab > span::after {
    @apply bg-tan_plaster;
  }

  .arrow-style-tab:last-child > span::after {
    content: none;
  }
}

@layer utilities {
  .font-onum {
    font-feature-settings: "onum" on;
  }

  .font-style-smooth {
    font-feature-settings: "ss01" on;
  }

  /* This doens't work, we need to get the udpated font */
  .font-cased-parens {
    font-feature-settings: "ss02" on;
  }

  /* Minca Design System Font Styles */

  .text-display-xl {
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -2.5px;
  }

  .text-display-lg {
    font-size: 57px;
    line-height: 64px;
    letter-spacing: -2.5px;
  }

  .text-display-md {
    font-size: 45px;
    line-height: 52px;
    letter-spacing: -2px;
  }

  .text-display-sm {
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0px;
  }

  .text-headline-lg {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0px;
    @apply font-medium;
  }

  .text-headline-md {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0px;
    @apply font-medium;
  }

  .text-headline-sm {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0px;
    @apply font-semibold;
  }

  .text-title-lg {
    @apply font-style-smooth;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0px;
  }

  .text-title-md {
    @apply font-style-smooth;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    @apply font-medium;
  }

  .text-title-sm {
    @apply font-style-smooth;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    @apply font-medium;
  }

  .text-label-xl {
    @apply font-style-smooth;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  .text-label-lg {
    @apply font-style-smooth;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  .text-label-md {
    @apply font-style-smooth;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }

  .text-label-sm {
    @apply font-style-smooth;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }

  .text-body-lg {
    @apply font-style-smooth;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .text-body-md {
    @apply font-style-smooth;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
  }

  .text-body-sm {
    @apply font-style-smooth;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }

  .text-body-xs {
    @apply font-style-smooth;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }

  /*
  Makes all text one size smaller than it would normally be if contained inside a <p> element.
  */
  .text-smaller {
    @apply text-body-xs;
    @apply md:text-body-sm;
    @apply lg:text-body-md;
  }
}
